import { calculateIRR } from "./calculateIRR"

/**
 * Process rental split for a listing
 * @param {Object} listing - The listing object containing financials, building information, and reservations
 * @returns {Object} - The updated listing object with processed financial data
 */
export const processRentalSplit = async listing => {
  // Extract financials, apartments, and reservations from the listing
  const financials = listing?.financials || {}
  const apartments = listing?.apartments || listing?.building?.apartments || []

  // Calculate initial purchase price and platform commission
  const purchasePriceFixed = (
    (parseFloat(listing?.startPrice || 0) / 95) *
    100
  ).toFixed(0)

  // Calculate initial purchase price and platform commission
  const purchasePrice = (
    (parseFloat(listing?.startPrice || 0) / 95) *
    100
  ).toFixed(0)

  const valvestCommissionFee = (purchasePrice / 95) * 100

  // Calculate total investment amounts
  const investmentAmount = purchasePrice

  const investmentAmountFixed = purchasePriceFixed

  // Calculate total bedrooms, bathrooms, and square meters
  let bedrooms = 0,
    bathrooms = 0,
    totalSquareMeter = 0
  apartments.forEach(apartment => {
    bedrooms += apartment.bedrooms || 0
    bathrooms += apartment.bathrooms || 0
    totalSquareMeter += apartment.squareMeter || 0
  })

  const sellingProceeds = parseFloat(financials?.expectedSellingPrice) || 0

  const details = {
    bedrooms,
    bathrooms,
    totalSquareMeter,
  }

  const interest = listing?.loanInterestRate

  const investmentProfitOnExit =
    investmentAmountFixed *
    ((listing.loanInterestExitRate / 100) * listing.loanPeriodInYears) *
    1

  const IRR = calculateIRR(
    parseFloat(investmentAmountFixed),
    interest / 100,
    1,
    listing?.loanPeriodInYears,
    0,
    investmentProfitOnExit
  )

  const rentReturnFixed = parseFloat(
    listing.apartments[0].rentCost.toString()?.replace(".", "")
  )
  const utilityCostFixed = parseFloat(
    listing.apartments[0].utilityCost.toString()?.replace(".", "")
  )

  // Prepare financial data
  const financialsData = {
    expectedSellingPrice: parseFloat(financials.expectedSellingPrice) || 0,
    purchasePrice,
    rentReturnFixed,
    utilityCostFixed,
    purchasePriceFixed,
    investmentAmount,
    investmentAmountFixed,
    valvestCommissionFee,
    sellingProceeds,
    NAR: listing?.loanInterestRate,
    IRR: IRR,
  }

  // Prepare updated listing object
  const NewListing = {
    ...listing,
    details,
    financials: financialsData,
  }

  return NewListing
}
